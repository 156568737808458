import { 
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild,
  asNativeElements 
} from '@angular/core';

@Component({
  selector: 'file-drop',
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss']
})
export class FileDropComponent implements AfterViewInit {

  @Input() public bAllowBrowse : boolean = false;
  @Output() private filesChangeEmiter : EventEmitter<File[]> = new EventEmitter();
  
  private background = '#ddd';
  private border_style = 'dashed';
  private border_width = '0.5px';
  private border_radius = '10px';
  @HostBinding('class.uploadzone') get uploadzoneClass () { return true };
  @HostBinding('style.background') get styleBackground() { return this.background; }
  @HostBinding('style.border-style') get styleBorderStyle() { return this.border_style; }
  @HostBinding('style.border-width') get styleBorderWidth() { return this.border_width; }
  @HostBinding('style.border-radius') get styleBorderRadius() { return this.border_radius; }
  
  @ViewChild('fileInput') fileInput !: ElementRef;

  constructor() { }

  ngAfterViewInit(): void {
    this.fileInput.nativeElement.addEventListener("change", ()=>{
      // console.log('upload: file selected');
      this.filesChangeEmiter.emit(Array.from(this.fileInput.nativeElement.files));
    }, false);
  }

  @HostListener('dragover', ['$event']) public onDragOver(e:any){
    e.preventDefault();
    e.stopPropagation();
    // this.background = '#CCC';
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(e:any){
    e.preventDefault();
    e.stopPropagation();
    // this.background = '#ddd'
  }

  @HostListener('drop', ['$event']) public onDrop(e:any){
    e.preventDefault();
    e.stopPropagation();
    // this.background = '#ddd';
    // console.log('upload: file dropped');

    let files = e.dataTransfer.files;
    this.filesChangeEmiter.emit(Array.from(files));
  }

  openFileContext() {
    if(this.bAllowBrowse)
    {
        // console.log('open browse');
        this.fileInput.nativeElement.click();
    }
  }
}
