import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-toggleable-panel',
  templateUrl: './toggleable-panel.component.html',
  styleUrls: ['./toggleable-panel.component.scss']
})
export class ToggleablePanelComponent {

    @Input() expandIcon!: string
    @Input() collapseIcon!: string
    @Input() isCollapsed:boolean = false;
}
