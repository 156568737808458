import { trigger, state, style, transition, animate } from '@angular/animations';
import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/shared/service/language.service';
import { pageConfig } from 'src/page-config';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
  animations: [
    trigger('textFadeIn', [
      state('hidden', style({
        opacity: 0,
        transform: 'translateX(0px)' // Optional: makes text slide in
      })),
      state('visible', style({
        opacity: 1,
        transform: 'translateX(0)'
      })),
      transition('hidden => visible', [
        animate('1s ease-in')
      ])
    ])
  ]
})

export class SideBarComponent {
  isSidebarExpanded: boolean; // Default value
  language : string = '';
  labelState = 'visible'; // Start with hidden state for label
  signOutPath : string[] = [];

  constructor( private languageServ : LanguageService, private cdr: ChangeDetectorRef,  private router : Router){
    this._setLanguage(this.languageServ.current);
    this.languageServ.language.subscribe((_language: string) => this._setLanguage(_language));
    this.isSidebarExpanded = true; // Set this to true or false based on your requirements
  }

  toggleSidebar() {
    this.isSidebarExpanded = !this.isSidebarExpanded;

    this.labelState = this.isSidebarExpanded? 'visible':'hidden';
    const sidebar = document.querySelector('.sidebar-container') as HTMLElement;
    const sidebar_menu = document.querySelector('.sidebar-menu') as HTMLElement;
    const sidebar_menu_below = document.querySelector('.sidebar-menu-below') as HTMLElement;
    const user_section = document.querySelector('.user-section') as HTMLElement;
    
    if (sidebar || sidebar_menu) {
      sidebar.style.width = this.isSidebarExpanded ? '340px' : '80px'; // Adjust the width according to the state
      sidebar_menu.style.paddingLeft = this.isSidebarExpanded ? '20px' : '0px'; // Adjust the width according to the state
      sidebar_menu_below.style.paddingLeft = this.isSidebarExpanded ? '20px' : '0px'; // Adjust the width according to the state
      user_section.style.paddingLeft = this.isSidebarExpanded ? '20px' : '0px'; // Adjust the width according to the state
      
    }

    // this.cdr.detectChanges();
}

  logout(){
    this.router.navigate(this.signOutPath);
  }

  private _setLanguage (_language : string) {
    this.language = _language;
    this.signOutPath= ['/', _language, ...pageConfig['pages']['signOut']];
  }
}
