import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() btn_text!:string;

  @Input() size: 'small' | 'medium' | 'large' = 'medium'; // Default size

  btnClass!: string;

  ngOnInit() {
    this.btnClass = this.getButtonClass();
  }

  getButtonClass(): string {
    switch (this.size) {
      case 'small':
        return 'btn-small';
      case 'medium':
        return 'btn-medium';
      case 'large':
        return 'btn-large';
      default:
        return 'btn-medium';
    }
  }
}
