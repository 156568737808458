import { Injectable } from '@angular/core';
import { IStringAnyMap } from 'src/app/app.types';
import { BehaviorSubject, merge, Observable, of, Subject } from 'rxjs';
import { map, tap, catchError, switchMap } from 'rxjs/operators';
import { BooxService } from './boox.service';
import { StorageService } from './storage.service';
import { environment } from 'src/environments/environment';

export interface ISessionModel  extends IStringAnyMap{

}

export interface ISignInCredential {
  email ?: string,
  username ?: string,
  password : string,
} 

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  
  public onChanges = new BehaviorSubject<boolean>(false);
  public onSession$ = new BehaviorSubject<ISessionModel|null>(null);
  public session : ISessionModel | null = null;

  constructor(
    private storageSrv : StorageService,
    private booxSrv : BooxService,
  ) {
    this.onSession$.subscribe((_session) => {
      this.session = _session;
    })
  }

  /**
   * Get the boox config
   * @param _booxName Boox config name
   * @returns environment boox config 
   */
  private _booxConfig (_booxName : string){
    var booxName = _booxName as keyof typeof environment.boox;
    return environment.boox[booxName];
  }

  /**
   * Load the current session from local storage
   * @returns 
   */
  public load () : Observable<ISessionModel|null> {

    let booxConfig = this._booxConfig(environment.defaultBoox);


    if(!!this.storageSrv.getItem(booxConfig.sessionKey, false)){
      return this.booxSrv.get('user/session', {})
        .pipe(
          catchError(() => {
            return of(null);
          }),
          tap((_status : ISessionModel|null) => {
            this.onSession$.next(_status);
            this.onChanges.next(!!_status);
          })
        );
    }
    else{
      return of(null);
    }
  }


  /**
   * Sign in 
   */
  signIn (_credentials : ISignInCredential) : Observable<ISessionModel|null> {
    
    let booxConfig = this._booxConfig(environment.defaultBoox);
    
    // 
    this.storageSrv.clearItem(booxConfig.sessionKey);
    // 
    return this.booxSrv.post('user/session', _credentials, {})
      .pipe(
        tap((_session:ISessionModel) => {
          this.storageSrv.setItem(booxConfig.sessionKey, _session['session_token']);
        }),
        catchError((_error) => of(null)),
        map((_data : ISessionModel|null) => {
          this.onSession$.next(_data);
          this.onChanges.next(!!_data);
          return _data;
        }),
      );
  }

  /**
   * Sign Out
   */
  signOut () {

    let booxConfig = this._booxConfig(environment.defaultBoox);

    // 
    return this.booxSrv.delete('user/session', {})
      .pipe(
        tap(() => {
          this.storageSrv.clearItem(booxConfig.sessionKey);
          this.onSession$.next(null);
          this.onChanges.next(false);
        })
      )
  }

  getUserSession(){
    return this.session;
  }
}
