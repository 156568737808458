import { Component } from '@angular/core';

@Component({
  selector: 'app-empty',
  template: '<router-outlet></router-outlet>',
  styles: [':host{height: 100% !important;}']
})
export class EmptyComponent {

}
