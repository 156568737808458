<ul class="list-unstyled m-0">
    <li *ngFor="let entry of menu" class="my-2">
        <!-- Headline -->
        <div *ngIf="entry['type'] === 'headline'" class="border-bottom pb-2 mb-2">{{entry['label']}}</div>
        <!-- horizontal line -->
        <hr *ngIf="entry['type'] === 'line'" class="border-bottom mb-2">
        <!-- this opens a local router link in this bowser window -->
        <a *ngIf="entry['type'] === 'link' && !entry['external'] && !entry['local']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" [routerLink]="entry['href']">
            <i *ngIf="entry['fa']" class="fa fa-fw" [ngClass]="entry['icon']" aria-hidden="true"></i>
            {{entry['label']}}
        </a>
        <!-- this opens an external link in a new bowser window-->
        <a *ngIf="entry['type'] === 'link' && entry['external']" [href]="entry['href']" target="_blank">
            <i *ngIf="entry['fa']" class="fa fa-fw" [ngClass]="entry['icon']" aria-hidden="true"></i>
            {{entry['label']}}
        </a>
        <!-- this opens a local link in this bowser window, the ng runtime will be destroyed -->
        <a *ngIf="entry['type'] === 'link' && entry['local']" [href]="entry['href']">
            <i *ngIf="entry['fa']" class="fa fa-fw" [ngClass]="entry['icon']" aria-hidden="true"></i>
            {{entry['label']}}
        </a>

    </li>
</ul>
