import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss']
})
export class SignaturePadComponent implements OnInit {
  public signatureNeeded = true;
  signaturePad!: SignaturePad;
  @ViewChild('canvas') canvasEl!: ElementRef;
  signatureImg!: string;

  ngOnInit() {
    this.signatureNeeded = true;
  }

  ngAfterViewInit() {
    const canvas = this.canvasEl.nativeElement;
    this.signaturePad = new SignaturePad(canvas);

    // Add event listeners for end of drawing
    canvas.addEventListener('mouseup', this.handleEndOfStroke.bind(this));
    canvas.addEventListener('touchend', this.handleEndOfStroke.bind(this));
    canvas.addEventListener('pointerup', this.handleEndOfStroke.bind(this));
  }

  // Clear the signature pad
  clearPad() {
    this.signaturePad.clear();
    this.signatureNeeded = true;
  }

  // Handle end of stroke events
  handleEndOfStroke() {
    if (!this.signaturePad.isEmpty()) {
      this.captureSignature();
    }
  }

  // Capture the signature as an image
  captureSignature() {
    this.signatureImg = this.signaturePad.toDataURL();
    this.signatureNeeded = false;
    console.log("Signature captured:", this.signatureImg);
  }

  get signature(): string{
    return this.signatureImg
  }
}
