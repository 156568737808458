import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-switch-button',
  templateUrl: './switch-button.component.html',
  styleUrls: ['./switch-button.component.scss']
})
export class SwitchButtonComponent{
  @Input() label_value: string = '';  // Text for the label
  @Input() labelPosition: 'left' | 'right' = 'right';  // Label position (default is right)
  @Input() switchId: string = 'switch1';

}
