import { Component } from '@angular/core';
import { LanguageService } from 'src/app/shared/service/language.service';

@Component({
  selector: 'layout-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  public language : string = '';

  constructor(
    private languageServ : LanguageService,
  ){
    this.language = this.languageServ.current;
    this.languageServ.language.subscribe((_language: string) => this.language = _language);
  }

}
