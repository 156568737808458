import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../service/language.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-language',
  template: '<router-outlet></router-outlet>',
  styles: [':host{height: 100% !important;}']
})
export class LanguageComponent implements OnInit{

  constructor(
    private route : ActivatedRoute,
    private languageServ : LanguageService,
  ){}

  ngOnInit(): void {
    this.route.params.subscribe((_params) => {
      if(_params['language']) {
        if(!this.languageServ.setCurrent(_params['language'])) {
          this.languageServ.switch();
        }
      }
    });
  }
}
