import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-step-icon',
  templateUrl: './step-icon.component.html',
  styleUrls: ['./step-icon.component.scss']
})
export class StepIconComponent {
  @Input() currentStepNumber: number = 1; // Default to first step

  steps: { number: number, imagePath: string }[] = [
    { number: 0, imagePath: '../assets/steps/progress_off.png' },
    { number: 1, imagePath: '../assets/steps/progress_1on.png' },
    { number: 2, imagePath: '../assets/steps/progress_2on.png' },
    { number: 3, imagePath: '../assets/steps/progress_3on.png' },
    { number: 4, imagePath: '../assets/steps/progress_4on.png' },
    { number: 5, imagePath: '../assets/steps/progress_5on_all.png' }
  ];

  getCurrentStep(): string {
    const value  = this.steps.find(step => step.number === this.currentStepNumber);
    if(value){
      return value.imagePath;
    }
    return ''
  }
}
