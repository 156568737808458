import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { SessionService } from '../service/session.service';
import { LanguageService } from '../service/language.service';
import { pageConfig } from 'src/page-config';


@Injectable({
  providedIn: 'root'
})
export class SessionGuard implements CanActivate, CanActivateChild {
  
  constructor(
    private sessionSrv : SessionService,
    private router : Router,
    private languageServ : LanguageService
  ) {}
  
  private _canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.sessionSrv.onChanges.pipe(
      tap((_status) => {
        if(!_status){
          this.router.navigate(['/', this.languageServ.current, ...pageConfig['pages']['signIn']], {
            queryParams : { redirect : state.url }
          });
        }
      })
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._canActivate(route, state);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._canActivate(childRoute, state);
  }
}
