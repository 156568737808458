import { Component } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { isArray, isString } from 'lodash';
import { from, map, of, tap } from 'rxjs';
import { IStringAnyMap } from 'src/app/app.types';
import { LanguageService } from 'src/app/shared/service/language.service';
import { pageConfig } from 'src/page-config';


const FA_ICON = /^fa-/;
const EXTERNAL_LINK = /^http(s)?:\/\//;

@Component({
  selector: 'layout-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

  public menu : IStringAnyMap[] = [];
  public language : string = '';

  constructor(
    private route : ActivatedRoute,
    private languageServ : LanguageService,
  ){

    this.language = this.languageServ.current;
    this.languageServ.language.subscribe((_language: string) => this.language = _language);

    this._getMenu();
    console.log(this.menu);
  }


  private _getMenu() : void {
    let route : ActivatedRouteSnapshot = this.route.snapshot;
    let data : IStringAnyMap = route.data;
    while (route.children.length){
      route = route.children[0];
      data = {...data, ...route.data};
    };

    let menuLabel = data['menu'] || null;
    let menuData = (!menuLabel || !pageConfig['menu'][menuLabel]) ? [] : pageConfig['menu'][menuLabel];

    this.menu = menuData.map((_menuEntry : IStringAnyMap) => {
      if(_menuEntry['type'] != 'link' ) return _menuEntry;

      let entry :IStringAnyMap = { ..._menuEntry };

      if(isArray(_menuEntry['link'])){
        entry['href'] = ['/', this.language, ..._menuEntry['link']];
      }
      else{
        entry['href'] = _menuEntry['link'];
      }

      entry['fa'] = FA_ICON.test(_menuEntry['icon']);
      entry['external'] = isString(_menuEntry['link']) && EXTERNAL_LINK.test(_menuEntry['link']);
      entry['local'] = isString(_menuEntry['link']) && !EXTERNAL_LINK.test(_menuEntry['link']);

      return entry;
    });
    
    // from(this.menu)
    //   .pipe(
    //     tap((_entry) => {

    //       if(_entry['type'] != 'link' ) return;

    //       if(isArray(_entry['link']))
    //         _entry['link'] = ['/', this.language, ..._entry['link']];

    //       _entry['fa'] = FA_ICON.test(_entry['icon']);
    //       _entry['external'] = isString(_entry['link']) && EXTERNAL_LINK.test(_entry['link']);
    //       _entry['local'] = isString(_entry['link']) && !EXTERNAL_LINK.test(_entry['link']);
    //     })
    //   )
    // .subscribe();
  }
}
