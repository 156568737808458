import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CookieService } from './cookie.service';
import { LanguageService } from './language.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class BootService {

  constructor(
    private cookieSrv : CookieService,
    private sessionSrv : SessionService,
    private languageServ : LanguageService,
  ) { }

  async load() {

    const promises: any[] = [];
    /**
     * Seems that auth promise (and probably others later) relies on having env loaded
     * So running it on parallel delivers unwanted/unexpected results sometimes
     * Therefore I can have it resolved sequentially before the others do it in parallel
     */
    await this.loadEnv();
    await this.loadSession();

    promises.push(this.loadExample());
    promises.push(this.loadLanguage());
    
    return Promise.all(promises);
  }

  /**
   * 
   * @returns {Promise}
   */
  private loadEnv() {
    return new Promise((_resolve, _reject) => {
      /** @todo load env from backend */
      console.log('Environment Boot');
      _resolve(true);
    });
  }

  /**
   * 
   * @returns {Promise}
   */
  private loadSession() {
    return new Promise((_resolve, _reject) => {
      this.sessionSrv.load().subscribe(() => {
        _resolve(true);
      })
    });
  }

  /**
   * 
   * @returns {Promise}
   */
  private loadExample () {
    return new Promise((_resolve, _reject) => {      
      _resolve(true);
    });
  }

  /**
   * 
   * @returns {Promise}
   */
  private loadLanguage () {
    return new Promise((resolve, reject) => {
      this.languageServ.loadFromCookie() || this.languageServ.loadFromBrowser() || this.languageServ.loadDefault();
      resolve(true);
    });
  }

}
