<p-button 
    [label]="label" 
    [ngClass]="buttonType" 
    [disabled]="disabled" 
    (onClick)="handleClick($event)"
    [loading]="loading"
    >
    <!-- Projected content for SVG or custom icon -->
    <ng-content select="[icon]"></ng-content>
    <!-- Button label -->
</p-button>
