<div class="d-flex vh-100" style="background-color: #FFFFFF;">
    <!-- <layout-header *ngIf="!this.routesWithoutHeader.includes(this.lastRoutePart)" class="header container-fluid d-flex flex-wrap justify-content-center border-bottom fixed-top"></layout-header> -->
    <!-- Sidebar -->
    <!-- <app-side-bar *ngIf="!this.routesWithoutHeader.includes(this.lastRoutePart)" class="flex-shrink-0"></app-side-bar> -->
    
    <!-- Main Content Area -->
    <main class="flex-grow-1 d-flex flex-column overflow-hidden">
      <div class="flex-grow-1 overflow-auto">
        <router-outlet></router-outlet>
      </div>
    </main>
  </div>
  