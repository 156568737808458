import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '../../service/language.service';

/**
 * This component can be use in the router configuration to navigate
 * to a specific route and keeping the current language in the path
 * 
 * @example
 * {
 *   path: '',
 *   component : RedirectComponent,
 *   data : {
 *     to : environment.pages.start
 *   },
 *   pathMatch: 'full',
 * }
 * 
 */
@Component({
  selector: 'app-redirect',
  template: '',
})
export class RedirectComponent {

  constructor(
    private router : Router,
    private route : ActivatedRoute,
    private languageServ : LanguageService,
  ) {
    this.route.data.subscribe((_data) => {
      var targetRoute = ['/', this.languageServ.current, ..._data['to'].filter((__:string) => !!__)];
      this.router.navigate(targetRoute);
    });
  }
}
