<div class="row">
    <div class="col-6 col-md-2 mb-3">
        <h5>Legal</h5>
        <ul class="nav flex-column">
            <li class="nav-item mb-2"><a [routerLinkActive]="['active']" [routerLink]="['/', language, 'imprint']" class="nav-link p-0 text-muted">Imprint</a></li>
            <li class="nav-item mb-2"><a [routerLinkActive]="['active']" [routerLink]="['/', language, 'data-protection']" class="nav-link p-0 text-muted">Data Protaction</a></li>
            <li class="nav-item mb-2"><a [routerLinkActive]="['active']" [routerLink]="['/', language, 'terms-of-use']" class="nav-link p-0 text-muted">Terms Of Use</a></li>
        </ul>
    </div>

    <div class="col-6 col-md-2 mb-3">
        <h5>Section</h5>
        <ul class="nav flex-column">
            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Home</a></li>
            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Features</a></li>
            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Pricing</a></li>
            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">FAQs</a></li>
            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">About</a></li>
        </ul>
    </div>

    <div class="col-6 col-md-2 mb-3">
        <h5>Section</h5>
        <ul class="nav flex-column">
            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Home</a></li>
            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Features</a></li>
            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Pricing</a></li>
            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">FAQs</a></li>
            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">About</a></li>
        </ul>
    </div>

    <div class="col-md-5 offset-md-1 mb-3">
        <form>
            <h5>Subscribe to our newsletter</h5>
            <p>Monthly digest of what's new and exciting from us.</p>
            <div class="d-flex flex-column flex-sm-row w-100 gap-2">
                <label for="newsletter1" class="visually-hidden">Email address</label>
                <input id="newsletter1" type="text" class="form-control" placeholder="Email address">
                <button class="btn btn-primary" type="button">Subscribe</button>
            </div>
        </form>
    </div>
</div>

<div class="d-flex flex-column flex-sm-row justify-content-between pt-2 border-top">
    <p>&copy; 2023 Flying Spoon GmbH, All rights reserved.</p>
    <ul class="list-unstyled d-flex">
        <li class="ms-3"><a class="link-dark" href="#"><i class="fa fa-facebook-official" aria-hidden="true"></i></a></li>
        <li class="ms-3"><a class="link-dark" href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
        <li class="ms-3"><a class="link-dark" href="#"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
    </ul>
</div>