<div class="card" style="border: none;">
    <p-panel class="toggleable-panel" [toggleable]="true" [expandIcon]="expandIcon" [collapseIcon]="collapseIcon" [toggler]="'header'" [collapsed]="isCollapsed" >
        <ng-template pTemplate="header">
            <div class="d-flex align-items-center gap-2 hover-section">
                <ng-content select="[icon]"></ng-content>
                <ng-content select="[header]"></ng-content>
            </div>
        </ng-template>
        <ng-template pTemplate="icons">
            <ng-content select="[toggle-icons-text]"></ng-content>
        </ng-template>
        <ng-template pTemplate="content">
            <ng-content select="[content]"></ng-content>
        </ng-template>
    </p-panel>
</div>
