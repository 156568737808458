import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppRoleModeService {

  private isAdmin:boolean = false;
  constructor() { }

  setMode(mode:boolean){
    this.isAdmin = mode;
  }

  isAdminMode(){
    return this.isAdmin;
  }
}
