<p-panel class="app-panel">
    <ng-template pTemplate="header">
        <div class="d-flex align-items-center gap-5 w-100">
            <div class="flex-grow-1">
                <ng-content select="[header]"></ng-content>
            </div>
            <div>
                <ng-content select="[icon]"></ng-content>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <ng-content select="[content]"></ng-content>
    </ng-template>
</p-panel>