import { Component, Input } from '@angular/core';
import { MediaImageMetaData } from 'src/app/view/other-view/start-view/start-view.component';

@Component({
  selector: 'app-masnory-gallery',
  templateUrl: './masnory-gallery.component.html',
  styleUrls: ['./masnory-gallery.component.scss']
})
export class MasnoryGalleryComponent {
  @Input() listOfBuilder!: MediaImageMetaData[];


  OpenSources(url:string){
    window.open(url, '_blank', 'width=1000,height=800');
  }
}
