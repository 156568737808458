export const environment = {
    production: false,

    boox: {
        scans: {
            host: 'https://api.dev.hero-software.app',
            apiKey: '454f7cc134a2c6ba1e818dc8bbb1cbbe6e8e146d6f59677cad0c7973f76c6b28',
            rootPath: 'api/v2',
            envPath: 'system/environment',
            headerApiKey: 'X-BooX-API-Key',
            headerSessionToken: 'X-BooX-Session-Token',
            sessionKey: 'session_api',
            appKey: 'app',
        },
    },
    defaultBoox: 'scans',

    STORAGE_KEY: {
        ENVIRONMENT: 'environment',
    },

    COOKIE_KEY: {
        LANGUAGE: 'language'
    },

    //
    storageSalt: 'djoi3df3dujiod',
};
