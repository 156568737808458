import { Pipe, PipeTransform } from '@angular/core';
import { tz } from 'moment-timezone';

@Pipe({
  name: 'timestamp'
})
export class TimestampPipe implements PipeTransform {

  transform(_ts: number, ...args: string[]): string {
    var format = args[0] || 'DD MMM HH:mm';
    var timeZone = args[1] || 'CET';
    return tz(_ts * 1000, timeZone).format(format);
  }

}
