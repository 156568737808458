import { Component } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-list-dialog',
  templateUrl: './list-dialog.component.html',
  styleUrls: ['./list-dialog.component.scss'],
  providers: [DialogService]
})
export class ListDialogComponent {
  items: any[];
  header: string;
  label: string  = 'name';

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this.items = config.data.items;
    this.header = config.data.header;
    this.label = config.data?.label;
  }

  onItemDblClick(event: any): void {
    this.ref.close(event.option);
  }

  onClose(): void {
    this.ref.close();
  }
}
