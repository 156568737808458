import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subject } from 'rxjs';
import { IStringStringMap } from 'src/app/app.types';
import { environment } from 'src/environments/environment';
import { CookieService } from './cookie.service';
import { pageConfig } from 'src/page-config';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private _language : string;  
  public language : Subject<string> = new Subject();

  constructor(
    private cookieServ : CookieService,
    private router : Router,
    private route : ActivatedRoute,
  ) {
    this._language = pageConfig['language']['default'];
  }

  /**
   * get the current language
   */
  get current () : string {
    return this._language;
  }

  /**
   * set the current language
   * @param _language string short language identifier 
   */
  setCurrent (_language : string) : boolean {
    var language = _language.toLocaleLowerCase();
    if(Object.keys(this.available).indexOf(language) >= 0)
    {
      this._language = language;
      this.language.next(this._language);
      this.writeToCookie();
      return true;
    }
    return false;
  }

  switch(_language : string|null = null) {
    _language = _language || this.default;
    if(!this.setCurrent(_language)){
      _language = this.default;
      this.setCurrent(_language);
    }

    let newPathMap = this.router.url.slice(1).split('/').slice(1);
        newPathMap.unshift(_language);
        newPathMap.unshift('');

    let newPath = newPathMap.join('/');

    if(this.router.url !== newPath){
      this.router.navigate([newPath]);
    }
  }

  /**
   * get all available languages configured in environment
   */
  get available () : IStringStringMap {
    return {...pageConfig['language']['available']};
  }

  /**
   * get the default language configured in environment
   */
  get default () : string {
    return pageConfig['language']['default'];
  }

  /**
   * loads the language settings from the cookie
   * @returns language key
   */
  loadFromCookie() : boolean {
    var language = this.cookieServ.getItem(environment.COOKIE_KEY.LANGUAGE);

    if(language)
    {
      return this.setCurrent(language);
    }
    else
    {
      this.cookieServ.clearItem(environment.COOKIE_KEY.LANGUAGE);
      return false;
    }
  }

  /**
   * writes the language setting to cookie
   */
  writeToCookie() : void {
    if(this._language)
    {
      this.cookieServ.setItem(environment.COOKIE_KEY.LANGUAGE, this._language);
    }
  }

  loadFromBrowser() : boolean {
    var language = navigator.language.toString().substring(0,2);
    if(language)
    {
      return this.setCurrent(language);
    }
    else
    {
      return false;
    }
  }

  loadDefault() : boolean {
    return this.setCurrent(this.default);
  }

}
