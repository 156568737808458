import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-hero-button',
  templateUrl: './hero-button.component.html',
  styleUrls: ['./hero-button.component.scss']
})
export class HeroButtonComponent {
  @Input() label: string = '';
  @Input() buttonType: 'primary' | 'secondary' | 'success' | 'error' = 'primary';
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;
  
  @Output() onClick = new EventEmitter<Event>();


  handleClick(event: Event) {
    if (!this.disabled) {
      this.onClick.emit(event);
    }
  }
}
