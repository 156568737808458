import { Injectable } from '@angular/core';
import { IStringAnyMap } from 'src/app/app.types';


@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor() { }

  /**
   * sets a cookie with the given key, value and expiration time in days
   * 
   * @param _cookieKey string cookie key
   * @param _cookieValue any cookie value
   * @param _expiresInXDays? number expiration time in days, if smaller then 0 it will delete the cookie, if there is not expiration time set the cookie is delete after the browser ist closed
   * 
   * @returns string string value of the given cookie value key
   */
  setItem (_cookieName : string, _cookieValue : any, _expiresInXDays ?: number) {

    // create cookie data set
    var cookieValue = {
      parse : false,
      value : _cookieValue,
    };
    
    // if the cookie value is not a simple type, stringify it and set prase flag for later
    if( ['number', 'string'].indexOf(typeof cookieValue.value) < 0 )
    {
        cookieValue.value = JSON.stringify(cookieValue.value);
        cookieValue.parse = true;
    }
        
    var cookieSet = [];
    cookieSet.push([_cookieName, JSON.stringify(cookieValue)].join('='));
    
    if(_expiresInXDays !== undefined)
    {
        var expirationDate = new Date();
        expirationDate.setTime(expirationDate.getTime() + (_expiresInXDays * 24 * 60 * 60 * 1000));
        cookieSet.push(['expires',expirationDate.toUTCString()].join('='));
    }
    
    cookieSet.push(['path','/'].join('='));
    
    document.cookie = cookieSet.join(';');

  }
  /**
   * gets a cookie value by given key
   * 
   * @param _cookieKey string cookie key
   * 
   * @returns string value of the given cookie key
   */
  getItem (_cookieKey : string) {

    var cookieData = document.cookie
        .split(';')
        .map(_dataSet => _dataSet.trim())
        .map(_dataSet => _dataSet.split('='))
        .reduce((_cookieData : IStringAnyMap, _dataSet) => {
            _cookieData[_dataSet[0]] = _dataSet.slice(1).join('=');
            return _cookieData;
        }, {});
    
    var cookieValue = cookieData[_cookieKey] !== undefined ? cookieData[_cookieKey] : null;
    
    if( typeof cookieValue === 'string')
    {
        try
        {
            var parsedCookieValue = JSON.parse(cookieValue);
            
            if(typeof parsedCookieValue === 'object')
            {
                if(parsedCookieValue.parse)
                    return JSON.parse(parsedCookieValue.value);
                else
                    return parsedCookieValue.value;
            }
            else{
              return cookieValue;
            }
        }
        catch(_error)
        {
            return cookieValue;
        }
    }
    else
    {
      return '';
    }
  }

  /**
   * deletes a cookie value by given key
   * 
   * @param _cookieKey string cookie key
   */
  clearItem (_cookieKey : string) {
    this.setItem(_cookieKey, '', -1);
  }

}
