import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(_items: any[], _field: string, _reverse: boolean = false): any[] {
    if (!_items) return [];

    if (_field) _items.sort((a, b) => a[_field] > b[_field] ? 1 : -1);
    else _items.sort((a, b) => a > b ? 1 : -1);

    if (_reverse) _items.reverse();

    return _items;
  }

}
