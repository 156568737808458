import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IStringAnyMap } from 'src/app/app.types';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

export interface IBooxRequestOptions {
  boox? : string; // if not set it will use default boox instance configured in environment
  headers? : IStringAnyMap; // additional headers
  apiKey?: boolean; // use API-Key default true
  auth?: boolean; // use Session-Key default true of sign in
  params? : IStringAnyMap; // query parameter
  responseType? : string; // 
  observe? : string
}


@Injectable({
  providedIn: 'root'
})
export class BooxService {

  constructor(
    private http: HttpClient,
    private store: StorageService,
  ) { }
  
  /**
   * Get the boox config
   * @param _booxName Boox config name
   * @returns environment boox config 
   */
  private _booxConfig (_booxName : string){
    var booxName = _booxName as keyof typeof environment.boox;
    return environment.boox[booxName];
  }

  /**
   * Send GET request to boox instance
   * 
   * @param _path {string} request path
   * @param _options {IBooxRequestOptions} request options
   * @returns {Observable<IStringAnyMap>}
   */
  get(_path: string, _options: IBooxRequestOptions = {}) : Observable<IStringAnyMap> {
    
    var booxName : string = _options.boox || environment.defaultBoox;
    var boox = this._booxConfig(booxName);

    var headers = {
      ... _options.headers,
    };

    if (_options.apiKey !== false){
      headers[boox.headerApiKey] = boox.apiKey;
    }

    var authToken = this.store.getItem(boox.sessionKey) || null;

    if (_options.auth !== false && authToken){
      headers[boox.headerSessionToken] = authToken;
    }
    else if (_options.auth === true){
      return new Observable(_sub => _sub.error('Authentication is required but there is no session'));
    }

    var url = [boox.host, boox.rootPath];
    if (_path) url.push(_path);

    var requestOptions : IStringAnyMap = {
      headers: headers,
      params: _options.params || {}
    };

    if(_options.responseType){
      requestOptions['responseType'] = _options.responseType;
    }

    return this.http.get(url.join('/'), requestOptions);
  }

  /**
   * Send POST request to boox instance
   * 
   * @param _path {string} request path
   * @param _data {any} request data
   * @param _options {IBooxRequestOptions} request options
   * @returns {Observable<IStringAnyMap>}
   */
  post(_path: string, _data: any, _options: IBooxRequestOptions = {}) : Observable<IStringAnyMap> {

    var booxName : string = _options.boox || environment.defaultBoox;
    var boox = this._booxConfig(booxName);

    var headers = {
      ... _options.headers,
    };

    if (_options.apiKey !== false) {
      headers[boox.headerApiKey] = boox.apiKey;
    }

    var authToken = this.store.getItem(boox.sessionKey) || null;
    if (_options.auth !== false && authToken){
      headers[boox.headerSessionToken] = authToken;
    }
    else if (_options.auth === true){
      return new Observable(_sub => _sub.error('Authentication is required but there is no session'));
    }
      

    var url = [boox.host, boox.rootPath];
    if (_path) url.push(_path);

    var requestOptions : IStringAnyMap = {
      headers: headers,
      params: _options.params || {}
    };

    if(_options.observe) requestOptions['observe'] = _options.observe;

    return this.http.post(url.join('/'), _data, requestOptions);
  }

  /**
   * Send PATCH request to boox instance
   * 
   * @param _path {string} request path
   * @param _data {any} request data
   * @param _options {IBooxRequestOptions} request options
   * @returns {Observable<IStringAnyMap>}
   */
  patch(_path: string, _data: any, _options: IBooxRequestOptions = {}) : Observable<IStringAnyMap> {

    var booxName : string = _options.boox || environment.defaultBoox;
    var boox = this._booxConfig(booxName);

    var headers = {
      ... _options.headers,
    };

    if (_options.apiKey !== false) {
      headers[boox.headerApiKey] = boox.apiKey;
    }

    var authToken = this.store.getItem(boox.sessionKey) || null;
    if (_options.auth !== false && authToken){
      headers[boox.headerSessionToken] = authToken;
    }
    else if (_options.auth === true){
      return new Observable(_sub => _sub.error('Authentication is required but there is no session'));
    }

    var url = [boox.host, boox.rootPath];
    if (_path) url.push(_path);

    var requestOptions : IStringAnyMap = {
      headers: headers,
      params: _options.params || {}
    };
    if(_options.observe) requestOptions['observe'] = _options.observe;

    return this.http.patch(url.join('/'), _data, requestOptions);
  }

  /**
   * Send PUT request to boox instance
   * 
   * @param _path {string} request path
   * @param _data {any} request data
   * @param _options {IBooxRequestOptions} request options
   * @returns {Observable<IStringAnyMap>}
   */
  put(_path: string, _data: any, _options: IBooxRequestOptions = {}) : Observable<IStringAnyMap> {

    var booxName : string = _options.boox || environment.defaultBoox;
    var boox = this._booxConfig(booxName);

    var headers = {
      ... _options.headers,
    };

    if (_options.apiKey !== false) {
      headers[boox.headerApiKey] = boox.apiKey;
    }

    var authToken = this.store.getItem(boox.sessionKey) || null;
    if (_options.auth !== false && authToken){
      headers[boox.headerSessionToken] = authToken;
    }
    else if (_options.auth === true){
      return new Observable(_sub => _sub.error('Authentication is required but there is no session'));
    }
      

    var url = [boox.host, boox.rootPath];
    if (_path) url.push(_path);

    var requestOptions : IStringAnyMap = {
      headers: headers,
      params: _options.params || {}
    };
    if(_options.observe) requestOptions['observe'] = _options.observe;

    return this.http.put(url.join('/'), _data, requestOptions);
  }

  /**
   * Send DELETE request to boox instance
   * 
   * @param _path {string} request path
   * @param _options {IBooxRequestOptions} request options
   * @returns {Observable<IStringAnyMap>}
   */
  delete(_path: string, _options: IBooxRequestOptions = {}) : Observable<IStringAnyMap> {

    var booxName : string = _options.boox || environment.defaultBoox;
    var boox = this._booxConfig(booxName);

    var headers = {
      ... _options.headers,
    };

    if (_options.apiKey !== false) {
      headers[boox.headerApiKey] = boox.apiKey;
    }

    var authToken = this.store.getItem(boox.sessionKey) || null;

    if (_options.auth !== false && authToken){
      headers[boox.headerSessionToken] = authToken;
    }
    
    else if (_options.auth === true){
      return new Observable(_sub => _sub.error('Authentication is required but there is no session'));
    }

    var url = [boox.host, boox.rootPath];
    if (_path) url.push(_path);

    return this.http.delete(url.join('/'), {
      headers: headers,
      params: _options.params || {}
    });
  }

}
