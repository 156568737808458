import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private _localStorage = window.localStorage;

  constructor() { }

  /**
   * 
   * @param _key 
   * @param _value 
   * @returns 
   */
  public setItem(_key : string, _value : any) {
    var value = JSON.stringify(_value);
    var phrase = CryptoJS.AES.encrypt(value, environment.storageSalt);
    this._localStorage.setItem(_key, phrase.toString());
    return true;
  }

  /**
   * 
   * @param _key 
   * @returns 
   */
  public getItem(_key: string, _fallback : any = null) {
    try {
      var value = this._localStorage.getItem(_key) || null;
      if(value === null) {
        return _fallback;
      }
      value = CryptoJS.AES.decrypt(value, environment.storageSalt).toString(CryptoJS.enc.Utf8);
      value = JSON.parse(<string>value);
      return value;
      
    } catch (e) {
      return _fallback;
    }
  }

  /**
   * 
   * @param _key 
   */
  public clearItem (_key : string){
    this._localStorage.removeItem(_key);
  }

  /**
   * 
   */
  public clear() {
    this._localStorage.clear();
  }
}
