import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  templateUrl: './private-layout.component.html',
  styleUrls: ['./private-layout.component.scss']
})
export class PrivateLayoutComponent {
  showHeader = true;
  routesWithoutHeader = ['sign-in', 'sign-out']; // Add your specific routes here
  lastRoutePart: string ='';

  constructor(private router: Router) {}
  ngOnInit() {  
    const urlSegments = this.router.url.split('/');
    if (urlSegments.length > 0) {
      this.lastRoutePart = urlSegments[urlSegments.length - 1];
    }
  }
}
