import { Component, EventEmitter, Input, Output } from '@angular/core';


interface DropdownOption {
  label: string;
  value: any;
}


@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {
  @Input() options: DropdownOption[] = [];
  @Output() selectionChange = new EventEmitter<any>();

 onDropdownChange(event: any) {
    this.selectionChange.emit(event.value);
  }
}
