<div class="row m-0 p-0 signature-panel">
    <div class="col-12 m-0 p-0 position-relative">
        <canvas #canvas width="400" height="100"></canvas>
        <div class="icon-container" (click)="clearPad()">
            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="43" viewBox="0 0 44 43" fill="none">
                <path d="M26.9292 29.563C29.2737 29.563 30.1868 30.6067 30.8803 32.9212L31.002 33.3461C31.329 34.5238 31.5244 34.8766 31.9204 35.0398C32.3846 35.2312 32.6942 35.2039 33.1806 34.9538L33.444 34.8063C33.5386 34.7494 33.6418 34.6838 33.7586 34.6068L34.9469 33.798C36.0504 33.0722 37.0419 32.5924 38.2491 32.2907C38.9692 32.1106 39.6987 32.5485 39.8786 33.2684C40.0587 33.9883 39.621 34.7178 38.9009 34.8979C38.1717 35.0803 37.5425 35.3562 36.8489 35.7747L36.3139 36.1166L35.4557 36.7043C35.044 36.9843 34.7333 37.1774 34.4096 37.3439C33.2577 37.936 32.1343 38.0348 30.8963 37.5245C29.5618 36.9746 29.0238 36.1272 28.52 34.44L28.2408 33.4692C27.916 32.4132 27.6946 32.2505 26.9292 32.2505C26.375 32.2505 25.8011 32.5279 25.0151 33.1876L24.684 33.4759L23.0341 35.0441C20.5112 37.4156 18.3604 38.5671 15.2809 38.5671C12.2597 38.5671 9.64671 38.112 7.45325 37.1896L12.7343 35.7486C13.5357 35.8358 14.3844 35.8796 15.2809 35.8796C17.4 35.8796 18.9211 35.1362 20.8104 33.4382L21.2731 33.0106L22.2259 32.0948C22.6499 31.6885 22.9676 31.3973 23.2874 31.1288C24.5122 30.1011 25.6255 29.563 26.9292 29.563ZM34.5982 5.3212C37.1019 7.82498 37.1019 11.8844 34.5982 14.3882L34.0819 14.9055C36.1448 17.4162 36.0702 20.0771 34.1516 22.0015L30.5661 25.587C30.0408 26.1112 29.1901 26.1105 28.6656 25.5854C28.1414 25.06 28.1421 24.2092 28.6674 23.6849L32.2484 20.1038C33.1174 19.2323 33.2179 18.1503 32.1771 16.8101L16.7382 32.2482C16.2422 32.7441 15.6252 33.1023 14.9484 33.2868L5.78278 35.7866C4.77967 36.0602 3.85923 35.1396 4.13282 34.1366L6.63252 24.971C6.81711 24.2941 7.17514 23.6772 7.6712 23.1811L25.5311 5.3212C28.035 2.81741 32.0944 2.81741 34.5982 5.3212ZM27.4315 7.22155L9.57155 25.0815C9.4062 25.2469 9.28685 25.4524 9.22533 25.6782L7.34435 32.575L14.2413 30.6939C14.4669 30.6325 14.6725 30.5132 14.8379 30.3478L32.6978 12.4879C34.1521 11.0336 34.1521 8.67581 32.6978 7.22155C31.2435 5.7673 28.8857 5.7673 27.4315 7.22155Z" fill="#DBD8D8"/>
            </svg>
        </div>
    </div>
</div>
