import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RedirectComponent } from './shared/component/redirect/redirect.component';
import { environment } from 'src/environments/environment';
import { LanguageComponent } from './shared/component/language/language.component';
import { PublicLayoutComponent } from './shared/layout/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './shared/layout/private-layout/private-layout.component';
import { SessionGuard } from './shared/guard/session.guard';
import { pageConfig } from 'src/page-config';

const routes: Routes = [
  {
    path: '',
    component : RedirectComponent,
    data : {
      to : pageConfig['pages']['signIn']
    },
    pathMatch: 'full',
  },
  {
    path : ':language',
    component : LanguageComponent,
    children : [
      {
        path : 'session',
        component: PublicLayoutComponent,
        loadChildren : () => import('./view/session-view/session-view.module').then(__ => __.SessionViewModule),
      },
      {
        path : 'dashboard',
        component: PrivateLayoutComponent,
        canActivateChild : [SessionGuard],
        loadChildren : () => import('./view/dashboard-view/dashboard-view.module').then(__ => __.DashboardViewModule),
      },
      {
        path : '',
        component: PrivateLayoutComponent,
        canActivateChild : [SessionGuard],
        loadChildren : () => import('./view/other-view/other-view.module').then(__ => __.OtherViewModule),
      },
      // {
      //   path: '',
      //   component : RedirectComponent,
      //   data : {
      //     to : environment.pages.start
      //   },
      //   pathMatch: 'full',
      // },
    ]
  },
  {
    path : '**',
    component : RedirectComponent,
    data : {
      to : pageConfig['pages']['notFound']
    },
    
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
